import React from 'react'
import { A, InlineA } from '../../Button'
import { BodyCopy } from '../../Globals'
import {
  Address,
  AddressWrapper,
  AddressLocationName,
  AddressFooter,
} from '../style'
import { generateRandomKey } from '@utils'
import ALink from '@components/A'
import { useTranslatedTerms } from '@hooks'

const renderAddress = address => {
  let addressArray = address

  if (address && address.includes('<br/>')) {
    addressArray = address.split('<br/>').filter(word => word.length > 1)
  } else {
    addressArray =
      address && address.split('<br />').filter(word => word.length > 1)
  }
  return (
    <>
      {addressArray ? (
        addressArray.map(line => (
          <span key={generateRandomKey()}>
            {line} <br />
          </span>
        ))
      ) : (
        <BodyCopy></BodyCopy>
      )}
    </>
  )
}

const AddressBody = ({
  borderLeft,
  linkToEntry,
  office: { title, address, phone, fax, slug },
  theme,
  locationNameSize,
  offices = false,
  linkToLocationInTitle = true,
}) => {
  const { getTranslatedTerm } = useTranslatedTerms()

  if (!linkToEntry) {
    return (
      <>
        <AddressLocationName
          textNormalCase={!borderLeft}
          color={theme === 'dark' ? 'white' : 'purple'}
          locationNameSize={locationNameSize}
          offices={offices}
          underlineHover={!linkToEntry}
        >
          {title}
        </AddressLocationName>
        <Address>
          {renderAddress(address)}
          <strong>{getTranslatedTerm('Phone')}: </strong>
          <InlineA href={`tel:${phone}`}>{phone}</InlineA>
          <br />
          <strong>{getTranslatedTerm('Fax')}: </strong>
          {fax}
          <br />
        </Address>
      </>
    )
  }
  return (
    <>
      <ALink to={slug} contentType="ContentfulOffice">
        <AddressLocationName
          textNormalCase={!borderLeft}
          color={theme === 'dark' ? 'white' : 'purple'}
          locationNameSize={locationNameSize}
          offices={offices}
          underlineHover
        >
          {title}
        </AddressLocationName>
      </ALink>
      <Address>
        {renderAddress(address)}
        {phone && (
          <InlineA href={`tel:${phone}`}>
            <strong>{getTranslatedTerm('Phone')}: </strong>
            {phone}
          </InlineA>
        )}
        <br />
        {fax && (
          <>
            <strong>{getTranslatedTerm('Fax')}: </strong>
            {fax}
          </>
        )}
        <br />
      </Address>
    </>
  )
}
const Directions = ({
  office: { street, city, state, postalCode, country },
  directionsFontSize,
}) => {
  const getDirections = () => {
    if (typeof window === 'undefined' || typeof navigator === 'undefined')
      return null

    // if iPhone ...
    if (
      navigator.platform.indexOf('iPhone') !== -1 ||
      navigator.platform.indexOf('iPod') !== -1 ||
      navigator.platform.indexOf('iPad') !== -1
    ) {
      return `http://maps.apple.com/?daddr=${street},+${city},+${state},+${postalCode}+${country}/`
    } else {
      return `https://maps.google.com/maps?daddr=${street},+${city},+${postalCode}+${country}/`
    }
  }

  return (
    <AddressFooter>
      <A href={getDirections()} hoverColor="gold">
        <BodyCopy color="gold" fontSize={directionsFontSize || 16}>
          Get directions
        </BodyCopy>
      </A>
    </AddressFooter>
  )
}

const AddressCard = ({
  borderLeft,
  linkToEntry = true,
  marginBottom,
  theme,
  directions,
  padding,
  width,
  ...rest
}) => (
  <AddressWrapper
    width={width}
    borderLeft={borderLeft}
    padding={padding}
    marginBottom={marginBottom}
    theme={theme}
  >
    <AddressBody
      {...rest}
      theme={theme}
      borderLeft={borderLeft}
      linkToEntry={linkToEntry}
    />
    {directions && <Directions {...rest} />}
  </AddressWrapper>
)

export default AddressCard
