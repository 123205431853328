import styled from 'styled-components'
import { colors, sizes } from '@theme'

export const Container = styled.div`
  width: 100%;
  padding: 40px 15px;
  display: flex;
  justify-content: center;
`

export const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: ${colors.gold};
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 40px 0;
  color: ${colors.gold};
  @media (max-width: ${sizes.small.max}) {
    width: 90%;
    margin: 0 auto;
    padding: 40px 15px 70px;
  }
`

export const PageStep = styled.span`
  padding: 0 10px;
  color: ${({ active, color }) =>
    active ? colors.text.purple : colors[color]};
  border-bottom: 2px solid
    ${({ active }) => (active ? colors.text.purple : 'transparent')};
  cursor: ${({ pointer }) => pointer && 'pointer'};
`

export const Step = styled.div`
  cursor: pointer;
  font-family: Arial, sans-serif;
  width: 32px;
  height: 32px;
  font-size: 16px;
  line-height: 28px;
  color: ${({ active, color, activeColor }) =>
    active ? colors[activeColor] : colors[color]};
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid
    ${({ active, activeColor }) =>
      active ? colors[activeColor] : 'transparent'};
  font-weight: 300;
  position: relative;

  &:hover {
    color: ${({ activeColor }) => colors[activeColor]};
    border-bottom: 2px solid ${({ activeColor }) => colors[activeColor]};
  }
`

// Circle Container
export const CircleContainer = styled.ul`
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  box-sizing: border-box;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0;

  & li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
  }
`

export const IconLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;

  &:hover {
    color: ${colors.gray};
    border-bottom: 2px solid ${colors.gray};
    cursor: pointer;
  }
`

// Circle Step
export const CircleStep = styled.button`
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
  margin: 0;
  overflow: visble;
  text-transform: none;

  &:before {
    background: ${({ active, stepperColor }) =>
      active ? 'transparent' : stepperColor || 'white'};
    opacity: ${({ active }) => (active ? 1 : 0.85)};
    border: ${({ active, stepperColor }) =>
      active
        ? stepperColor
          ? `2.52px solid ${stepperColor}`
          : '2.52px solid white'
        : ''};
    font-size: 0px;
    border-radius: 100%;
    height: 13px;
    width: 13px;
    line-height: 20px;
    position: absolute;
    top: 0px;
    left: 0px;
    content: '•';
    text-align: center;
    color: transparent;
  }
`

export const StepContainer = styled.div`
  margin: '0 10px';
`
