export const breakpoints = ['575px', '765px', '992px', '1280px']
export const fontSizes = [10, 12, 16, 18, 20, 24, 30, 32, 38, 48, 54, 56, 64]
export const lineHeights = [1, 1.2, 1.4, 1.6, 1.8, 2]
export const fontWeights = [300, 400, 500, 600]
export const space = [0, 4, 8, 16, 32, 48, 64, 128, 256, 512]

export const colors = {
  bg: {
    default: '#FFFFFF',
    reverse: '#5F6062',
    reverseDark: '#000000',
    wash: '#FAFAFA',
    darkWash: '#E6E6E6',
    divider: '#B4A06E',
    border: '#EBECED',
    inactive: '#DFE7EF',
    lightGray: 'rgba(39,37,31,0.2)',
  },
  primary: {
    burgundy: '#8B0E04',
    gray: '#5F6062',
    gold: '#B4A06E',
    gold75: '#B4A06E',
    red: '#78232F',
    red80: '#87434D',
    red60: '#97646B',
    red40: '#A68489',
    red20: '#B6A5A7',
    stone: '#D5D1C4',
    stone80: '#DDDAD0',
    stone60: '#E6E3DC',
    stone40: '#EEEDE7',
    stone20: '#F7F6F3',
  },
  secondary: {
    cream: '#B18B5A',
    purple: '#5A3462',
    black: '#000000',
    blue: '#44797B',
    blue80: '#699495',
    blue60: '#8FAFB0',
    blue40: '#B4C9CA',
    blue20: '#DAE4E5',
    gold: '#C79110',
    gold80: '#D2A740',
    gold60: '#DDBD70',
    gold40: '#E9D39F',
    gold20: '#F4E9CF',
  },
  social: {
    facebook: '#3B5998',
    twitter: '#00ACED',
    google: '#ea4335',
    instagram: '#D85537',
  },
  text: {
    default: '#5F6062',
    secondary: '#5F6062',
    alt: '#5F6062',
    placeholder: '#B4B8BE',
    reverse: '#FFFFFF',
    burgundy: '#8B0E04',
    gray: '#5F6062',
    gold: '#B4A06E',
    gold75: '#B4A06E',
    cream: '#B18B5A',
    purple: '#5A3462',
    black: '#000000',
    white: '#FFFFFF',
    grayMd: '#5f606254',
  },
  gradient: {
    redTan: {
      start: '#8B0E04',
      finish: '#B18B5A',
    },
  },
  system: {
    error: '#FF1111',
    success: '#40DA0A',
  },
  misc: {
    disclaimer: '#969696',
    coldGray: '#FAFAFA',
    cardStroke: '#D1B4B6',
  },
  default: '#5F6062',
  alt: '#5F6062',
  grayMd: '#5f606254',
  error: '#D8000C',
  reverse: '#FFFFFF',
  burgundy: '#8B0E04',
  gray: '#5F6062',
  grayLt: '#d8d8d8',
  gold: '#B4A06E',
  gold75: '#a99564',
  borderGray: '#B4B8BE',
  cream: '#B18B5A',
  purple: '#5A3462',
  black: '#000000',
  white: '#FFFFFF',
  border: '#DDE0E3',
  transparentWhite: 'rgba(221,224,227,0.2)',
  tBorder: 'rgba(255, 255, 255, 0.2)',
  bgGray: '#f3f3f3',
  goldLt: '#eae6db',
  transparent: 'transparent',
}

// define breakpoints based off of section layouts

export const sizes = {
  xsmall: { min: '0px', max: '574px' },
  small: { min: '575px', max: '764px' },
  medium: { min: '765px', max: '991px' },
  large: { min: '992px', max: '1279px' },
  xlarge: { min: '1280px', max: Infinity },

  // Sidebar/nav related tweakpoints
  largerSidebar: { min: 1100, max: 1339 },
  sidebarFixed: { min: 2000, max: Infinity },

  // Topbar related tweakpoints
  expandedSearch: { min: 1180, max: Infinity },

  grid: {
    gridSize: 12,
    gutterWidth: 1,
    outerMargin: 2,
    outerPadding: '15px',
    maxWidth: '1200px',
    maxWidthCentered: '650px',
  },
}

// Typography

const fonts = {
  primary: 'mrs-eaves, Garamond, Georgia, serif',
  secondary: 'arial, sans-serif',
  tertiary: 'Helvetica',
  quaternary:
    'Helvetica Neue, Arial, Verdana, -apple-system, BlinkMacSystemFont, sans-serif',
}

const descriptiveFontSizes = {
  body: '16px',
  bodySmall: '14px',
  bodyLarge: '24px',
  description: '16px',
  descriptionLarge: '20px',
  descriptionSmall: '12px',
  button: '16px',
  navigation: '24px',
  navSmall: '20px',
  h1: {
    desktop: '48px',
    mobile: '40px',
  },
  h2: {
    desktop: '40px',
    mobile: '32px',
  },
  h3: {
    desktop: '28px',
    mobile: '24px',
  },
  h4: {
    desktop: '20px',
    mobile: '18px',
  },
}

const descriptiveFontWeights = {
  regular: 400,
  bold: 700,
}

const descriptiveLineHeights = {
  body: '24px',
  bodyLarge: '32px',
  bodySmall: '24px',
  description: '24px',
  descriptionLarge: '28px',
  descriptionSmall: '20px',
  button: '26px',
  navigation: '18px',
  h1: {
    desktop: '52px',
    mobile: '44px',
  },
  h2: {
    desktop: '44px',
    mobile: '35px',
  },
  h3: {
    desktop: '38px',
    mobile: '26px',
  },
  h4: {
    desktop: '23px',
    mobile: '20px',
  },
}

export const SSsizes = [0, 4, 8, 16, 32, 64, 128, 256, 512]

const theme = {
  colors,
  sizes: SSsizes,
  breakpoints,
  fontSizes,
  lineHeights,
  fontWeights,
  space,
  descriptiveFontSizes,
  descriptiveFontWeights,
  descriptiveLineHeights,
  fonts,
}

// Non-Styled System theme properties
export const pageGutterMargins = ['16px', '64px', '128px', '256px']

export default theme
