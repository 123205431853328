import React from 'react'
import A from '@components/A'
import {
  HoverGalleryContainer,
  StyledHoverGallery,
  StyledHoverGalleryItem,
  CtaTitle,
  Caption,
  HoverGalleryItem,
} from './style'
import Icon from '../Icon'
import { generateRandomKey } from '@utils'
import CtaLink from './CtaLink'
import { H2 } from '../Globals'
import { Row } from '@components/Common'
import DescriptionGrid from './DescriptionGrid'

const HoverGallery = ({ children, layout, title }) => {
  switch (layout) {
    case 'Spaced Grid':
      const width = `${(1 / children.length) * 100}%`
      return (
        <HoverGalleryContainer
          style={{ padding: '60px 30px', background: 'white' }}
        >
          <H2 color="purple" marginBottom={20}>
            {title}
          </H2>
          <Row>
            {children?.map(({ id, image, ...rest }) => (
              <HoverGalleryItem
                data-testid="gallery-item"
                width={width}
                key={generateRandomKey()}
                bg={image?.fluid?.src || image?.file?.url}
              >
                <CtaLink {...rest} />
              </HoverGalleryItem>
            ))}
          </Row>
        </HoverGalleryContainer>
      )
    case 'Description Grid':
      return <DescriptionGrid title={title}>{children}</DescriptionGrid>
    default:
      return (
        <HoverGalleryContainer>
          <StyledHoverGallery>
            {children?.map(({ id, image, linksTo, title }) => (
              <StyledHoverGalleryItem key={id}>
                <A
                  to={linksTo?.[0]?.slug}
                  contentType={linksTo?.[0]?.__typename}
                >
                  <img
                    src={`https:${image?.fluid?.src || image?.file?.url}`}
                    alt={`Image: ${title}`}
                    width="481"
                    height="300"
                  />
                  <Caption>
                    <CtaTitle>{title}</CtaTitle>
                    <Icon
                      glyph="enter"
                      id={`arrow${id}`}
                      fill="white"
                      style={{ display: 'inline-block' }}
                      height={40}
                      width={40}
                    />
                  </Caption>
                </A>
              </StyledHoverGalleryItem>
            ))}
          </StyledHoverGallery>
        </HoverGalleryContainer>
      )
  }
}

export default HoverGallery
