import React from 'react'
import PropTypes from 'prop-types'
import { twMerge } from 'tailwind-merge'

const Container = ({ children, className, shrink, fluid }) => {
  return (
    <div
      className={twMerge(
        'font-default mx-auto md:w-full sm:max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1200px]',
        className,
        fluid && 'sm:max-w-full md:max-w-full lg:max-w-full xl:max-w-full',
        shrink && 'h-[64px]'
      )}
    >
      {children}
    </div>
  )
}

export default Container

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  shrink: PropTypes.bool,
  fluid: PropTypes.bool,
}
