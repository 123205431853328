import React, { useState, useEffect } from 'react'
// import { Row, Col } from '../Globals'
import { Column, Row } from '@components/Common'
import Card from '../Card'
import { findStepperArr } from '@utils'
import PaginationStepper from './PaginationStepper'

const InsightsStepper = ({ insights, pageSlugs }) => {
  const totalCards = insights?.length
  const cardsPerPage = 8
  const totalSteppers = totalCards && Math.ceil(totalCards / cardsPerPage)
  const initialStepper = {
    currentPage: 1,
    totalSteppers,
    currentCards:
      totalSteppers &&
      findStepperArr('leftBlock', {
        totalSteppers,
      }),
  }

  const [pageData, setPage] = useState(initialStepper)

  // if total cards which is the length of flattened arr changes then this needs to run in order to get correct steppers. state needs to represent the length of cards after the dropdown was changed.
  useEffect(() => {
    setPage(initialStepper)
  }, [totalCards])

  const getListOfCards = (arr, page) => {
    const maxIndex = page && page * 8
    return arr?.slice(maxIndex - 8, maxIndex)
  }

  return (
    <>
      <Row className="mb-5">
        {insights &&
          getListOfCards(insights, pageData?.currentPage)?.map((item, i) => (
            <Column
              className="md:w-1/2 p-10 border-r border-b border-t-0 border-l-0 border-solid border-ph-border"
              key={`${item.id} + ${i}`}
            >
              <Card
                className="qtph-featured-insight"
                tag={item.category}
                title={item.title}
                item={item}
                id={item.id}
                to={item?.slug}
                slugContentType="ContentfulArticle"
                slugCategory={item?.categorySlug}
              />
            </Column>
          ))}
        <div className="w-full flex justify-center">
          {pageData?.totalSteppers > 1 && (
            <PaginationStepper pageData={pageData} setPage={setPage} />
          )}
        </div>
      </Row>
    </>
  )
}

export default InsightsStepper
