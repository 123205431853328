import styled from 'styled-components'
import { sizes, colors } from '@theme'
import { Row } from '../Globals'

export const HoverGalleryContainer = styled.div`
  float: left;
  position: relative;
  width: 100%;
  display: block;
  padding: 0px;
`

export const StyledHoverGallery = styled.ul`
  display: block;
  margin: 0px;
  padding: 0px;
  list-style: none;
  overflow: hidden;
`

export const StyledHoverGalleryItem = styled.li`
  float: left;
  width: ${({ width }) => width || '33.3%'};
  display: block;
  margin: 0px;
  padding: 0px;
  list-style: none;
  overflow: hidden;
  box-sizing: border-box;

  & a {
    display: block;
    position: relative;
    color: #b4a06e;
    text-decoration: none;
    background-color: transparent;

    &:after {
      content: '';
      display: block;
      background: rgba(0, 0, 0, 0.33);
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      z-index: 1;
    }

    & img {
      display: block;
      width: 100%;
      height: auto;
      position: relative;
      z-index: 0;
      transition: all 0.3s ease-out;
      vertical-align: middle;
      border-style: none;
    }

    &:hover img {
      transform: scale(1.1);
    }

    &:hover:after {
      background: rgba(0, 0, 0, 0.75);
    }
  }
  @media (max-width: ${sizes.medium.min}) {
    width: 100%;
  }
`

export const Caption = styled.span`
  padding: 20px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  z-index: 3;
`

export const CtaTitle = styled.span`
  display: block;
  margin: 0px 0px 25px;
  font-size: ${({ fontSize }) => fontSize || '34px'};
  font-weight: 700;
  line-height: 38px;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  color: #ffffff;
`

export const GalleryItemTitle = styled(CtaTitle)`
  line-height: 24px;
  padding: 20px;
  font-size: 20px;
`

export const HoverGalleryItem = styled.div`
  width: ${({ width }) => `calc(${width} - 15px)` || '33.3%'};
  display: block;
  float: left;
  margin: 10px 15px 10px 0;
  box-sizing: border-box;
  background: ${({ bg }) => `url(${bg})`};
  background-size: cover;
  background-position: center;
  min-height: 150px;

  & a {
    display: block;
    position: relative;
    color: #b4a06e;
    text-decoration: none;
    background-color: transparent;
    height: 100%;

    & :after {
      content: '';
      display: block;
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      z-index: 1;
    }

    & :hover:after {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  @media (max-width: ${sizes.medium.min}) {
    width: 45%;
    margin: 10px 10px 10px 0;
  }
  @media (max-width: ${sizes.small.min}) {
    width: 100%;
  }
`
export const DescriptionContainer = styled(Row)`
  width: ${({ width }) => width || '33.3%'};

  @media (max-width: ${sizes.medium.min}) {
    width: 100%;
  }
`

export const Description = styled.div`
  margin: 25px 0px;
  padding: 0 15px 0 0;
  font-size: 16px;
  color: ${colors.text.gray};
  line-height: 1.5;
`
