/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components'
import A from '@components/A'
import { animated } from 'react-spring'
import { H2, Container as MainContainer } from '../Globals'
import { colors, sizes } from '@theme'
import moneyBg from '@assets/images/moneymattersbg.jpg'
import pathwayBg from '@assets/images/element2.png'
import { StyledDropdownMenu } from '../Dropdown/style'

// export const NavContainer = styled(MainContainer)`
//   display: flex;
//   position: relative;
//   align-items: center;
//   transition: all 0.3s ease-out;
//   height: ${({ shrink }) => (shrink ? '64px' : '76px')};
// `

export const StyledNavHeader = styled.header`
  color: ${colors.black};
  position: fixed;
  width: 100%;
  z-index: 1030;
  text-align: center;
  border-bottom: ${props =>
    props.isSubNavVisible ? `1px ${colors.bg.border} solid` : colors.white};
  background: ${props =>
    props.isSubNavVisible
      ? `linear-gradient(180deg, ${colors.white} 60%, ${colors.bg.border} 100%)`
      : colors.white};
  box-shadow: ${({ displayShadow }) =>
    displayShadow
      ? '0 4px 8px 0 rgba(0,0,0,0.5)'
      : '0 0 5px 0 rgba(0,0,0,0.5)'};
`

export const Nav = styled.nav`
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;

  @media only screen (min-width: ${sizes.xsmall.min}) and (max-width: ${sizes
      .xsmall.max}) {
    flex-grow: 1;
    width: auto;
  }
  @media only screen (max-width: ${sizes.small.max}) {
    mask-image: linear-gradient(
      to right; transparent; black 20px; black 90%; transparent
    );
  }
  @media only screen (min-width: ${sizes.small.min}) and (max-width: ${sizes
      .medium.max}) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media only screen (min-width: ${sizes.xlarge.min}) {
    width: null;
  }
`
export const StyledAppBarList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const NavH2 = styled(H2)`
  white-space: nowrap;
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  color: inherit;

  &:hover {
    color: inherit;
  }
`

export const StyledNavItem = styled.div`
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-out;
  font-weight: 300;
  padding: ${({ login }) => (!login ? '15px 15px' : '17px 15px 15px 10px')};
  position: relative;
  border-radius: 5px;
  flex-wrap: flex;
  white-space: nowrap;

  a, h1, h2, h3, h4, h5, h6 {
    text-decoration: none;
  }

  color: ${colors.black};
  border: 1px solid ${colors.white}; 
  ${({ isHovered, subNav }) =>
    isHovered &&
    subNav &&
    `
    border: 1px solid ${colors.primary.gold}; 
    color: ${colors.gold};
  `}
  
  &:after{
    content: '';
    position: absolute;
    width: 0; 
    height: 1px;
    display: block;
    margin-top: 6px;
    right: 0;
    background: ${({ subNav, language }) =>
      subNav || language ? colors.white : colors.gray};
    transition: width .2s ease;
    -webkit-transition: width .2s ease;
  }

  &:hover ${StyledDropdownMenu} {
    display: block;
  }
  
  &:hover:after{
    width: 100%;
    left: 0;
    background: ${({ subNav, language }) =>
      subNav || language ? colors.white : colors.gray}
  }

  &:focus {
    outline: 0;
    background-color: ${colors.lighter};
    color: ${colors.white};
  };
  @media only screen (min-width: ${sizes.xsmall.min}) and (max-width: ${
  sizes.small.max
}) {
    padding-left: 8px;
    padding-right: 8px;
  };
  @media only screen (min-width: ${sizes.small.min}) and (max-width: ${
  sizes.medium.max
}) {
    padding-left: 10px;
    padding-right: 10px;
  };
  @media only screen (min-width: ${sizes.large.min}) {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;

    &:hover {
      color: ${colors.brand};
    };
  };
};

`

export const StyledNavItemTopBar = styled.div`
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-out;
  font-weight: 300;
  position: relative;
  height: 100%;
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    display: block;
    margin-top: -5px;
    right: 0;
    background: ${colors.gray};
    transition: width 0.2s ease;
    -webkit-transition: width 0.2s ease;
  }

  &:hover ${StyledDropdownMenu} {
    display: block;
  }

  &:hover .caret {
    transform: rotate(-180deg);
  }

  &:hover:after {
    width: 100%;
    left: 0;
    background: ${colors.gray};
  }

  &:focus {
    outline: 0;
    background-color: ${colors.lighter};
    color: ${colors.white};
  }
`

export const StyledNavItemLink = styled(A)`
  // &:after{
  //   content: '';
  //   position: absolute;
  //   width: 0;
  //   height: 3px;
  //   display: block;
  //   margin-top: 5px;
  //   right: 0;
  //   background: blue;
  //   transition: width .2s ease;
  //   -webkit-transition: width .2s ease;
  // }

  // &:hover:after{
  //   width: 100%;
  //   left: 0;
  //   background: blue;
  // }
`

export const DropdownContent = styled(animated.div)`
  will-change: transform, opacity, height;
  overflow: hidden;
  text-align: left;
  ${({ dropdown }) => dropdown === 'open' && 'margin-bottom: 30px'};
`

export const StyledSubNav = styled(animated.div)`
  width: 45ch;
  height: 45ch;
  background: grey;
  border-radius: 5px;
  background-image: url(https://drscdn.500px.org/photo/435236/q%3D80_m%3D1500/v2?webp=true&sig=67031bdff6f582f3e027311e2074be452203ab637c0bd21d89128844becf8e40);
  background-size: cover;
  background-position: center center;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s;
  will-change: transform;
  border: 15px solid white;

  &:hover {
    box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
  }
`

export const Button = styled('button')`
  cursor: pointer;
  pointer-events: all;
  outline: 0;
  border: none;
  background: transparent;
  display: flex;
  align-self: flex-end;
  overflow: hidden;
  margin: 0;
  padding: 0;
  padding-bottom: 14px;
  color: rgba(255, 255, 255, 0.5);
  :hover {
    color: rgba(255, 255, 255, 0.6);
  }
`

export const StyledHeader = styled.header`
  color: ${colors.black};
  width: 100%;
  z-index: 1030;
  border-bottom: ${props =>
    props.isSubNavVisible ? `1px ${colors.bg.border} solid` : colors.white};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
`

// Mobile Styles
export const StyledMobileAppBar = styled.nav`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const StyledNavTop = styled.div`
  /* height: 100%; */
  width: 100%;
  display: flex;
  text-align: left;
  justify-content: space-between;
`

export const StyledNavContainer = styled(MainContainer)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 992px) {
    flex-wrap: nowrap;
    max-width: 960px;
  }
`

export const StyledLogo = styled(A)`
  max-width: 150px;
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  font-family: 'HelveticaNeueLTStd-Bd';
  font-weight: 400;
  font-style: normal;
  text-decoration: none;

  & a {
    display: block;
    text-decoration: none;
  }
  & svg {
    display: block;
    width: 116px;
    height: 50px;
    max-width: 116px;
    vertical-align: middle;
    border-style: none;
  }
`

export const StyledNavToggleButton = styled.button`
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
  padding: 10px 5px 10px 5px;
  margin: 0px;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  float: right;
  text-decoration: none;
  outline: none !important;

  & svg {
    display: block;
    width: 50px;
    height: 50px;
    max-width: 50px;
    vertical-align: middle;
    border-style: none;
  }
`

export const StyledNavItems = styled(animated.div)`
  will-change: transform, opacity, height;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
  align-items: center;
  flex-basis: 100%;
  max-height: 80vh;
  border-top: 1.5px solid #dde0e3;
`

export const StyledNavList = styled.ul`
  margin-left: auto !important;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
`

export const StyledNavListItem = styled.li`
  margin: 0px;
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;
  width: 100%;
  position: static;
  border-bottom: 1.5px solid #dde0e3;
`

export const StyledNavLinkMobileTitle = styled(A)`
  position: relative;
  margin: 0 auto;
  width: 100%;
`
export const StyledNavLink = styled.div`
  color: #000000;
  position: relative;
  padding: 8px;
  text-align: left;
  display: block;

  &:after {
    content: '';
    display: block;
    margin: auto;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 15px;
    width: 0;
    height: 0;
    border-top: ${({ dropdown }) =>
      dropdown === 'open'
        ? '0px'
        : dropdown === 'closed'
        ? '6px solid #B4B8BE'
        : null};
    border-bottom: ${({ dropdown }) =>
      dropdown === 'open'
        ? '6px solid #B4B8BE'
        : dropdown === 'closed'
        ? '0px'
        : null};
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
`

export const StyledMobileLinkContainer = styled.div`
  margin: 8px 0px 4px 10px;

  &:hover {
    color: ${colors.gold};
  }
`

export const StyledMobileLink = styled(A)`
  ${({ sm }) => sm && 'font-size: 14px'};
  ${({ sm }) => sm && 'text-decoration: underline'};

  ${StyledMobileLinkContainer} {
    ${({ sm }) => sm && 'margin: 0px 0px 0px 10px'};
  }
`

export const StyledSearch = styled.li`
  color: #000000;
  position: relative;
  padding-left: 0px;
`

export const SubNavLink = styled(A)`
  display: inline-block;
  text-decoration: none;
`

export const SubNavBanner = styled.div`
  padding: 0px;
  min-height: 250px;
  overflow: hidden;
  margin: 0px;
  position: relative;
  text-align: left;
`
export const SubNavImageHero = styled.img`
  background-image: ${({ img }) => img};
  background-color: black;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  position: absolute;
  left: 0px;
  top: 10px;
  z-index: 0;
  width: 200px;

  @media (max-width: 1199px) {
    left: -35px;
    width: 102%;
  }
  @media (max-width: 991px) {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  &:after {
    content: '';
    display: block;
    background: ${`url(${pathwayBg})`} no-repeat 0 0;
    background-size: contain;
    position: absolute;
    top: 0;
    bottom: 0px;
    left: 0px;
    z-index: 0;
    right: 0;
    pointer-events: none;
  }
`
export const SubNavFeaturedImageCaption = styled.span`
  padding: 40px 0px 30px;
  margin-left: 10px;
  display: block;
  padding: 78px 0px;
  color: #ffffff;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  max-width: 412px;
  position: relative;
  z-index: 3;

  & span {
    display: block;
  }
`

export const SubNavFeaturedImageTitle = styled.span`
  margin: 0px 0px 20px;
  font-size: 30px;
  line-height: 34px;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  display: block;
  font-weight: 400;
`

// Styled Nav
export const DropDownMenu = styled.div`
  position: fixed;
  float: none;
  top: ${({ shrink }) => (shrink ? '91px' : '98px')};
  left: 0px;
  z-index: 1000;
  display: block;
  background: #ffffff;
  border: none;
  border-radius: 0px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  padding: 30px 20px 50px;
  margin: 0px;
  height: 408px;
  width: 100vw;
  transition: all 0.3s ease-out;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  pointer-events: auto;
  text-align: left;
  overflow-y: scroll;

  &:after {
    content: '';
    display: block;
    background: url(${pathwayBg}) no-repeat 0 0;
    width: 500px;
    height: 390px;
    background-size: auto 500px;
    position: absolute;
    bottom: 0px;
    left: -10vw;
    z-index: 0;
    pointer-events: none;
    @media (max-width: 1150px) {
      left: -15vw;
    }
    @media (max-width: 1050px) {
      left: -17vw;
    }
  }
`

export const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  text-align: center;
  margin: 0 auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }
`
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`

export const SubNavFeaturedBanner = styled.div`
  position: static;

  @media (min-width: 992px) {
    flex: ${({ cols }) => (cols === 2 ? '0 0 41.666667%' : '0 0 66.666667%')};
    max-width: ${({ cols }) => (cols === 2 ? '41.666667%' : '66.666667%;')};
  }
  @media (max-width: 1199px) {
    position: relative;
  }
`

const HeroLinkImageStyles = css`
  & img {
    background-image: ${({ img }) => (img ? `url(${img})` : `url(${moneyBg})`)};
    display: block;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    position: absolute;
    left: 0px;
    top: 10px;
    z-index: 0;

    @media (max-width: 1200px) {
      width: ${({ cols }) => (cols === 1 ? '625px' : '385px')};
      height: ${({ cols }) => cols === 2 && '270px'};
    }

    @media (max-width: ${sizes.xlarge.max}) {
      left: -35px;
      width: 102%;
    }
  }
`

export const HeroLink = styled(A)`
  display: inline-block;
  text-decoration: none;
  ${HeroLinkImageStyles}
`

export const HeroLinkImage = styled.div`
  display: inline-block;
  ${HeroLinkImageStyles}
`

export const SubNavCaption = styled.span`
  display: block;
  padding: 78px 0px;
  color: #ffffff;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  max-width: 412px;
  position: relative;
  z-index: 3;
  color: #ffffff;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  text-align: left;
  @media (min-width: 1199px) {
    position: absolute;
    left: 120px;
  }
`

export const SubNavTitle = styled.span`
  margin: 0px 0px 20px;
  font-size: 30px;
  line-height: 34px;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  display: block;
`

export const SubNavSubTitle = styled.span`
  display: block;
`

export const SubNavLinks = styled.div`
  border-left: 1px solid #dde0e3;
  padding-left: 60px;

  @media (min-width: 992px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  @media (max-width: 1199px) {
    padding-left: 20px;
  }
`
export const SubNavLinksCol2 = styled.div`
  padding-left: 20px;

  @media (min-width: 992px) {
    flex: 0 0 25%;
    max-width: 25%;
  }

  @media (max-width: 1199px) {
    padding-left: 20px;
  }
`

export const StyledSubNavList = styled.ul`
  margin-right: 0px;
  display: block;

  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  text-align: left;
`

export const SubNavListItem = styled.li`
  margin: 0px;
`

export const SubNavListItemLink = styled(A)`
  display: block;
  padding: ${({ sm }) => (sm ? '0px' : '10px 0px')};
  margin: ${({ sm }) => (sm ? '0px 10px 0px 0px' : '0px 1px 0px 0px')};
  line-height: 28px;
  letter-spacing: 0.39px;
  color: #27251f;
  font-size: ${({ sm }) => (sm ? '14px' : '18px')};
  text-decoration: ${({ sm }) => (sm ? 'underline' : 'none')};
  &:hover {
    color: ${colors.gold};
  }
`

export const FlagItem = styled.div`
  display: block;
  width: 100%;
  padding: 0px 8px;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;

  &:hover {
    background-color: ${colors.grayLt};
  }
`

export const LanguageTitle = styled.div`
  padding: 0px 0px 0px 4px;
  font-size: 16px;
  color: inherit;
`

export const LanguageLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
export const StyledNavTopBarBackgroundWrapper = styled.div`
  height: 30px;
  background-color: #ebe5d9;
`

export const StyledNavTopBarContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;

  margin-right: auto;
  margin-left: auto;

  @media (max-width: ${sizes.medium.max}) {
    max-width: ${({ fluid }) => (fluid ? '100%' : '540px')};
    width: 100%;
    ${({ mobilePadding }) => mobilePadding && 'padding-left: 15px'};
    ${({ mobilePadding }) => mobilePadding && 'padding-right: 15px'};
  }

  @media (min-width: ${sizes.medium.min}) {
    max-width: ${({ fluid }) => (fluid ? '100%' : '770px')};
  }

  @media (min-width: ${sizes.large.min}) {
    max-width: ${({ fluid }) => (fluid ? '100%' : '1010px')};
  }

  @media (min-width: ${sizes.large.max}) {
    max-width: ${({ fluid }) => (fluid ? '100%' : '1250px')};
  }
`
export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
`
