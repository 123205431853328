import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledNavTopBarBackgroundWrapper,
  StyledNavTopBarContainer,
  StyledNavItemTopBar,
} from '../style'
import LanguagePicker from '../../LanguagePicker'
import LoginPicker from './LoginPicker'

const DesktopTopBar = props => {
  return (
    <StyledNavTopBarBackgroundWrapper>
      <StyledNavTopBarContainer>
        <StyledNavItemTopBar language login style={{ marginRight: '20px' }}>
          <LoginPicker />
        </StyledNavItemTopBar>
        <StyledNavItemTopBar language>
          <LanguagePicker {...props} />
        </StyledNavItemTopBar>
      </StyledNavTopBarContainer>
    </StyledNavTopBarBackgroundWrapper>
  )
}

DesktopTopBar.propTypes = {}

export default DesktopTopBar
