import React from 'react'
import { Divider } from '../Globals'
import { Row, Column, Container } from '@components/Common'
import Card from '../Card'
import {
  StyledEntriesContainer,
  StyledEntriesTitle,
  StyledViewMoreContainer,
  StyledViewMoreSpan,
} from './style'
import { generateRandomKey, capitalize } from '@utils'
import { nodeLocaleToEnglishLanguage } from '../../../gatsby/createPagesUtils/nodeHelpers'
import { useLocale } from '@hooks'

const typeObj = {
  ContentfulNews: 'NEWS',
  ContentfulEvent: 'EVENT',
  ContentfulPracticeArea: 'PRACTICE AREA',
  ContentfulOffice: 'OFFICE',
  ContentfulArticleCategory: 'BLOG',
  ContentfulProfessional: 'PROFESSIONAL',
}

const HomeArticlesGrid = ({
  entries,
  title,
  displayTitle,
  displayArticleTag,
  theme,
  slug,
  linkTitle,
}) => {
  const { locale } = useLocale()

  const display = entry =>
    !entry.displayOnSites ||
    entry.displayOnSites.includes(nodeLocaleToEnglishLanguage[locale])
  entries = entries?.filter(entry => display(entry))?.slice(0, 3)

  const getEntryTag = entry => {
    let type = entry.__typename
    const isPreview = !entry?.__typename?.includes('Contentful')
    if (isPreview) {
      type =
        (entry.__typename === 'ArticleGroup' && 'ContentfulArticleCategory') ||
        `Contentful${capitalize(entry?.__typename)}`
    }
    const articleCategory = type === 'ContentfulArticle'
    const contentTag = entry.tag || typeObj[type] || ''

    return (articleCategory && entry?.category?.title) || contentTag
  }
  return (
    <StyledEntriesContainer
      fluid
      color={theme === 'dark' ? 'purple' : 'white'}
      paddingBottom={120}
    >
      {displayTitle && (
        <Container fluid>
          <Row className="px-[15px] justify-center">
            <Column className="md:w-11/12 xl:w-2/3">
              <StyledEntriesTitle
                className="qtph-home-accolades-txt"
                color="black"
              >
                {title}
              </StyledEntriesTitle>
            </Column>
          </Row>
        </Container>
      )}
      <Divider
        fitToWidth
        color={theme === 'dark' ? 'transparentWhite' : undefined}
        style={{ marginBottom: '30px' }}
      />
      <Row className="px-[15px] pb-5 justify-center">
        <Column className="sm:w-5/6 md:w-11/12 xl:w-2/3 md:flex-row">
          {entries &&
            entries.map(entry => (
              <Column
                className="relative min-h-[1px] md:w-1/2 lg:w-1/3 xl:w-1/3 pr-5 pb-[15px] md:py-0 md:px-2 sm:pt-0 sm:px-[15px] sm:pb-[30px]"
                key={generateRandomKey()}
              >
                <Card
                  title={entry.title}
                  key={entry.id}
                  theme={theme}
                  item={entry}
                  id={entry.id}
                  tag={displayArticleTag && getEntryTag(entry)}
                  titleMargin="8px 0px 8px"
                  titleSize="20px"
                  titleColor="purple"
                  to={entry?.slug}
                  slugContentType={entry?.__typename}
                  slugCategory={entry?.category?.slug}
                />
              </Column>
            ))}
        </Column>
      </Row>
      <Divider
        fitToWidth
        color={theme === 'dark' ? 'transparentWhite' : undefined}
      />
      {slug && (
        <Container>
          <StyledViewMoreContainer>
            <StyledViewMoreSpan
              to={slug}
              color={theme === 'dark' ? 'white' : undefined}
              className={`qtph-home-all${slug}-link`} // we are already assuming the slug is formatted good enough to display as text, lets just use it as classname for testing
            >
              {linkTitle || `View all ${slug}`}
            </StyledViewMoreSpan>
          </StyledViewMoreContainer>
        </Container>
      )}
    </StyledEntriesContainer>
  )
}

const EntriesBlock = ({ layout, entries, theme, ...rest }) => {
  switch (layout) {
    case 'homeArticleGrid':
      return (
        <HomeArticlesGrid
          entries={entries}
          theme={theme?.toLowerCase()}
          {...rest}
        />
      )
    default:
      return (
        <Container>
          <Row>
            <Column>
              <Row className="mb-[30px]" />
            </Column>
          </Row>
        </Container>
      )
  }
}

EntriesBlock.propTypes = {}

export default EntriesBlock
