import styled, { createGlobalStyle, css } from 'styled-components'
import theme, { colors, sizes } from '@theme'

export const StyledMain = styled.main`
  min-height: calc(100vh - 450px);
`

export const BodyWrapper = styled.main`
  flex: 1 0 auto;
  padding-top: 106px;
  background-color: ${({ bg }) => (bg ? colors[bg] : null)};

  @media (max-width: ${sizes.medium.max}) {
    padding-top: 59px;
`

const fontStack = css`
  font-family: Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
`

export const GlobalStyles = createGlobalStyle`
  html {
    ${fontStack};
    height: 100%;
  }
  body {
    color: black;
    background-color: white;
    height: 100%;
  }

  h1 {
    font-family: ${theme.fonts.primary};
    font-family: mrs-eaves, serif;
    font-style: normal;
    font-weight: ${theme.descriptiveFontWeights.bold};
    font-size: ${theme.descriptiveFontSizes.h1.mobile};
    line-height: ${theme.descriptiveLineHeights.h1.mobile};

    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.descriptiveFontSizes.h1.desktop};
      line-height: ${theme.descriptiveLineHeights.h1.desktop};
    }
  }

  h2 {
    font-family: ${theme.fonts.primary};
    font-weight: ${theme.descriptiveFontWeights.bold};
    font-size: ${theme.descriptiveFontSizes.h2.mobile};
    line-height: ${theme.descriptiveLineHeights.h2.mobile};

    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.descriptiveFontSizes.h2.desktop};
      line-height: ${theme.descriptiveLineHeights.h2.desktop};
    }
  }

  h3 {
    font-family: ${theme.fonts.primary};
    font-weight: ${theme.descriptiveFontWeights.bold};
    font-size: ${theme.descriptiveFontSizes.h3.mobile};
    line-height: ${theme.descriptiveLineHeights.h3.mobile};

    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.descriptiveFontSizes.h3.desktop};
      line-height: ${theme.descriptiveLineHeights.h3.desktop};
    }
  }

  h4 {
    font-family: ${theme.fonts.secondary};
    font-weight: ${theme.descriptiveFontWeights.bold};
    font-size: ${theme.descriptiveFontSizes.h4.mobile};
    line-height: ${theme.descriptiveLineHeights.h4.mobile};

    @media (min-width: ${theme.breakpoints[1]}) {
      font-size: ${theme.descriptiveFontSizes.h4.desktop};
      line-height: ${theme.descriptiveLineHeights.h4.desktop};
    }
  }

  input,
  label,
  textarea,
  button,
  p {
    font-family: ${theme.fonts.secondary};
    font-weight: ${theme.descriptiveFontWeights.regular};
    font-size: ${theme.descriptiveFontSizes.body};
    line-height: ${theme.descriptiveLineHeights.body};
  }


  mark {
    background-color: ${theme.colors.secondary.gold40};
  }
`
