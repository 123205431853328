import styled from 'styled-components'
import A from '@components/A'
import { BodyCopy, fontStack, Col } from '../Globals'
import { colors, sizes } from '@theme'
import defaultBg from '@assets/images/homebg.jpg'
import { calculateResponsiveFontSize } from '@utils'

// Default Carousel
export const SlideWrapper = styled.div`
  position: absolute;
  will-change: transform, opacity;
  align-items: normal !important;
  width: 100%;
  height: 100%;
  top: 30px;

  @media (max-width: ${sizes.small.min}) {
    top: 0px;
  }
`

const alignCenterMargins = `
  left: 0;
  right: 0;
`

export const StepperContainer = styled.div`
  position: absolute;
  padding: ${({ stepperPadding }) => stepperPadding || '0px 5% 60px 6%'};
  bottom: ${({ stepperBottom }) => stepperBottom || 0};
  text-align: ${({ alignStepper }) => alignStepper || ''};
  margin: 0 auto;
  z-index: 500;
  ${({ align }) => (align === 'center' ? alignCenterMargins : '')}

  @media (max-width: ${sizes.xsmall.max}) {
    padding: ${({ mobileStepperPadding }) =>
      mobileStepperPadding || '0px 20px 10vh'};
  }
`

export const StyledCarousel = styled.div`
  // height: 100%;
  width: 100%;
  position: relative;
  height: calc(56vh - 30px);

  @media (max-width: 1200px) {
    height: calc(90vh - 30px);
  }

  @media (max-width: 991px) {
    height: 90vh;
  }
`

export const StyledControls = styled.div`
  display: flex;
  height: 0;
  top: ${({ controlTop }) => controlTop || '40%'};
  margin: 0px;
  position: relative;
  right: 0;
  left: 0;
  list-style: none;
  z-index: 1000;

  & div:last-child {
    margin-left: auto;
  }
`

export const StyledSlideContainerCol = styled(Col)`
  padding: ${({ slidePadding }) => slidePadding || '78px 0px'};

  @media (max-width: 450px) {
    padding: ${({ mobileSlidePadding }) => mobileSlidePadding || '78px 0px'};
  }
`

export const FullWidthWrapper = styled.section`
  @media (min-width: 1200px) {
    width: 100vw;
  }

  @media (max-width: 1200px) {
    height: 94vh;
    width: 100vw;
    margin-top: 0px;
  }
`

export const StyledSlideContainerFullWidth = styled.div`
  height: 100vh;
  max-width: 100%;
  background: ${({ bg }) =>
    bg
      ? `linear-gradient(to top, ${colors.purple} 0%, ${colors.purple} 11%, rgba(0, 0, 0, 0) 110%), url(${bg})`
      : `linear-gradient(to top, ${colors.purple} 0%, ${colors.purple} 11%, rgba(0, 0, 0, 0) 85%), url(${defaultBg})`};
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: -30px;
  padding-bottom: 100px;

  @media (min-width: 1201px) {
    height: 60vh;
    // padding: 105px 0px 20px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: ${({ bg }) =>
      bg
        ? `linear-gradient(to top, ${colors.purple} 0%, ${colors.purple} 11%, rgba(0, 0, 0, 0) 110%), url(${bg})`
        : `linear-gradient(to top, ${colors.purple} 0%, ${colors.purple} 11%, rgba(0, 0, 0, 0) 85%), url(${defaultBg})`};
    background-size: cover;
  }
`
// FullWidth Carousel Old
export const FullWidthCarouselInner = styled.div`
  text-align: center;
  // margin: 22vh auto 0;
  // position: relative;
  // width: 90%;
  // display: flex;
  // flex-direction: column;
  @media (max-width: 1200px) {
    // margin: 35vh 5% 0;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
  }
`

export const StyledSlideFullWidthTitleDefault = styled.h2`
  ${fontStack};
  text-align: center;

  margin: 0px 15px 0px;
  color: ${({ color }) => color || colors.white};
  font-size: ${({ title }) => calculateResponsiveFontSize(title, 40)}px;
  font-weight: 600;
  line-height: ${({ title }) => (title.length > 150 ? 30 : 40)}px;
  letter-spacing: 0.4px;
  padding: 0px 5% 10px;
  & a {
    color: ${({ color }) => color || colors.white};
  }

  @media (max-width: ${sizes.large.min}) {
    font-size: ${({ title }) => calculateResponsiveFontSize(title, 36)}px;
    line-height: normal;
  }

  @media (max-width: ${sizes.medium.min}) {
    font-size: ${({ title }) => calculateResponsiveFontSize(title, 34)}px;
    line-height: normal;
  }

  @media (max-width: ${sizes.small.min}) {
    font-size: ${({ title }) => calculateResponsiveFontSize(title, 32)}px;
`

export const StyledSlideFullWidthButtonDefault = styled(A)`
  color: ${({ color }) => color || colors.white} !important;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 1.71px;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  &:hover {
    color: ${({ color }) => color || colors.white};
    text-decoration: underline;
  }
`

export const StyledSlideFullWidthExternalLinkDefault = styled.a`
  color: ${({ color }) => color || colors.white} !important;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 1.71px;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  &:hover {
    color: ${({ color }) => color || colors.white};
    text-decoration: underline;
  }

  @media (max-width: ${sizes.medium.min}) {
    font-size: ${({ title }) => calculateResponsiveFontSize(title, 20)}px;
  }

  @media (msx-width: ${sizes.small.min}) {
    font-size: ${({ title }) => calculateResponsiveFontSize(title, 20)}px;
  }
`
// / Default

export const StyledSlideTagDefault = styled.div`
  ${fontStack};
  color: white;
  border-bottom: 1px solid rgba(221, 224, 227, 0.2);
  display: block;
  font-family: inherit;
  padding: 0px 0px 4px;
  margin: 0px 0px 30px;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 600;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
`

export const StyledSlideTitleDefault = styled.h2`
  ${fontStack};
  margin: 1.6em 0em 0em;
  text-align: left;
  margin: 0px 0px 20px;
  font-size: 40px;
  line-height: 42px;
  font-weight: 600;
  letter-spacing: normal;

  @media (max-width: ${sizes.small.max}) {
    font-size: 32px;
  }
  & a {
    color: ${({ color }) => color || colors.white};

    &:hover {
      color: ${colors.gold};
    }
  }
`

export const StyledSlideBodyDefault = styled(BodyCopy)``

export const StyledSlideButtonDefault = styled(A)`
  color: ${({ color }) => color || colors.gold} !important;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 1.71px;
  display: inline-block;
  margin: 1.6em 0;
  text-decoration: none;
  &:hover {
    color: ${colors.gold};
    text-decoration: underline;
  }
`

// Slider

export const StyledSliderContainer = styled.div`
  margin: 0px;
  padding: 75px 0px 75px 200px;
  position: relative;
  display: flex;
  overflow: hidden;
  width: ${({ width }) => width || '100%'};

  @media (max-width: ${sizes.large.max}) {
    padding: 85px 0px 110px 42px;
  }

  @media (max-width: ${sizes.medium.max}) {
    padding: 55px 20px;
  }

  @media (max-width: ${sizes.small.max}) {
    padding: 80px 20px;
  }
`

export const StyledSlideTitle = styled.span`
  margin: 11px 0px 14px;
  color: ${({ color }) => color || colors.white};
  font-size: 30px;
  line-height: 34px;
  font-weight: 600;
  @media (max-width: ${sizes.medium.max}) {
    font-size: 20px;
  }
`

export const StyledSlide = styled.div`
  border-bottom: 3px solid;
  border-color: rgba(255, 255, 255, 0.2);
  display: block;
  float: left;
  margin-right: 90px;
  max-width: 445px;
  min-width: 445px;
  width: 100%;
  height: 220px;
  box-sizing: border-box;
  & a {
    color: #ffffff;
    text-decoration: none;
    &:hover {
      border-color: ${colors.purple};

      ${StyledSlideTitle} {
        color: ${colors.purple};
        text-decoration: none;
      }
    }
  }
  &:hover {
    border-color: ${colors.purple};
  }

  @media (max-width: ${sizes.large.max}) {
    max-width: 440px;
    min-width: 440px;
    margin-right: 60px;
  }

  @media (max-width: ${sizes.medium.max}) {
    max-width: 375px;
    min-width: 375px;
    margin-right: 30px;
  }
  @media (max-width: ${sizes.small.max}) {
    max-width: 100%;
    min-width: 100%;
    margin-right: 40px;
  }
`

export const StyledSlideLink = styled(A)`
  display: block;
  padding: 0px;
  outline: none;

  @media (max-width: ${sizes.medium.max}) {
    padding: 0px 30px 50px 0px;
  }
`

export const StyledSlideTag = styled.span`
  display: block;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
`

export const StyledSlideSubtitle = styled.span`
  color: ${({ color }) => color || colors.white};
  font-size: 18px;
  display: block;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  width: 100%;
  min-height: 10px;
`
