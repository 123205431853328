import styled from 'styled-components'
import { Container, H2 } from '../Globals'
import { sizes, colors } from '@theme'
import A from '@components/A'

export const StyledEntriesContainer = styled(Container)``

export const StyledEntriesTitle = styled(H2)`
  color: ${colors.gold};
  padding: 50px 0px 5px;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  width: 100%;
  box-sizing: border-box;
  ${({ titleMarginTop }) => titleMarginTop && `margin-top: ${titleMarginTop}`};
  @media (max-width: ${sizes.small.max}) {
    padding: 50px 15px 5px;
  }
`

export const StyledViewMoreContainer = styled.div`
  width: 100%;
  text-align: right;
  display: block;
  padding: 10px 0 60px;
  margin: 10px;
  font-size: 14px;
  letter-spacing: 0.4px;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  @media (max-width: ${sizes.small.max}) {
    width: 90%;
  }
  text-decoration: none;
`

export const StyledViewMoreSpan = styled(A)`
  color: ${({ color }) => (color ? colors[color] : colors.gold)};
  text-align: right;
  font-size: 14px;
  letter-spacing: 0.4px;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  padding: 10px 0 60px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
