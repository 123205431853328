import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import StackedBannerComponent, {
  WideStackedBanner,
} from './components/StackedBanner'
import StyledBanner from './components/Banner'
import { H1, H3 } from '../Globals'
import A from '@components/A'
import getInTouchBg from '@assets/images/Get_In_Touch_Banner.webp'
import elements from '@assets/images/element3.png'
import { useLocale, useTranslatedTerms } from '@hooks'

const GetInTouchImage = {
  image: {
    fluid: {
      src: getInTouchBg,
    },
  },
}

const DefaultImage = {
  fluid: {
    src: elements,
  },
}

const Banner = props => {
  const { stacked, wideStacked } = props

  if (stacked) {
    return <StackedBannerComponent {...props} />
  } else if (wideStacked) {
    return <WideStackedBanner {...props} />
  }
  return <StyledBanner {...props} />
}

Banner.propTypes = {}
Banner.defaultProps = {
  align: 'left',
  heroImage: DefaultImage,
  gradient: 'purple',
  text: true,
}

export default Banner
export const HeroBanner = props => <Banner {...props} />
export const StackedBanner = props => <Banner stacked {...props} />

export const GetInTouch = ({ preview }) => {
  const { locale } = useLocale()
  const { getTranslatedTerm } = useTranslatedTerms()
  const data = useStaticQuery(graphql`
    query GetInTouchQuery {
      contact: allContentfulPage(
        filter: { contentful_id: { eq: "6yjRT47C0DKdMhCVVzuP3b" } }
      ) {
        nodes {
          title
          slug
          node_locale
        }
      }
    }
  `)

  const contactSlug = data?.contact?.nodes?.filter(
    s => locale === s.node_locale
  )
  const previewSlug = 'preview?contentType=page&id=6yjRT47C0DKdMhCVVzuP3b'
  const slug = preview ? previewSlug : contactSlug?.[0]?.slug || 'contact'

  return (
    <Banner
      align="center"
      height="sm"
      heroImage={GetInTouchImage.image}
      gradient={false}
    >
      <H1>{getTranslatedTerm('Get In Touch With Us')}</H1>
      <A to={slug} hoverColor="gold" underline contentType="ContentfulPage">
        <H3>{getTranslatedTerm('Contact Us')}</H3>
      </A>
    </Banner>
  )
}
