/* eslint-disable no-nested-ternary */
import React from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { StyledLogo, StyledAppBarList, Nav, SearchContainer } from '../style'
import { Container } from '@components/Common'
import NavItemDesktop from './NavItemDesktop'
import DesktopTopBar from './DesktopTopBar'
import Search from '../../Search'
import PHLogo from '@assets/svg/ph-logo.svg'
import { useLocale } from '@hooks'

const AppBarDesktop = ({ shrink, navItems, homeSlug = '/', ...rest }) => {
  const { locale } = useLocale()

  const handleSubmit = e => {
    e.preventDefault()
    const value = e?.target?.querySelector('input')?.value || ''
    const url =
      locale !== 'en-US'
        ? `/${locale}/search?search=${value}`
        : `/search?search=${value}`
    navigate(url, {
      state: { query: value },
    })
  }

  return (
    <>
      <DesktopTopBar homeSlug={homeSlug} />
      <Container
        className="flex relative items-center transition-all duration-300 ease-out h-[76px]"
        shrink={shrink}
      >
        <StyledLogo to={homeSlug} contentType={'ContentfulPage'}>
          <PHLogo width="116px" height="100%" id="qtph-globalmenu-phlogo" />
        </StyledLogo>
        <Nav id="primary-nav">
          <StyledAppBarList className="qtph-globalmenu-navigation">
            {navItems?.map(({ id, displayText, navigationLink, ...rest }) => (
              <NavItemDesktop
                key={id}
                displayText={displayText}
                shrink={shrink}
                {...navigationLink}
                {...rest}
              />
            ))}
          </StyledAppBarList>
          <SearchContainer className="qtph-globalmenu-search-txtfield">
            <Search small handleSubmit={handleSubmit} />
          </SearchContainer>
        </Nav>
      </Container>
    </>
  )
}

AppBarDesktop.propTypes = {
  shrink: PropTypes.bool.isRequired,
  navItems: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default AppBarDesktop
