/* eslint-disable react/no-children-prop */
import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import { useSpring, a } from 'react-spring'
import { useMeasure, usePrevious } from '@hooks'
import {
  StyledLogo,
  StyledMobileAppBar,
  StyledNavToggleButton,
  StyledNavItems,
  StyledNavTop,
  StyledNavContainer,
} from '../style'
import NavItemsMobile from './NavItemsMobile'
import Icon from '../../Icon'
import PHLogo from '@assets/svg/ph-logo.svg'

const MobileAppBar = memo(({ navItems, homeSlug = '/' }) => {
  const [visible, setVisible] = useState(false)
  const previous = usePrevious(visible)
  const [bind, { height: viewHeight }] = useMeasure()
  const { height, opacity, transform } = useSpring({
    from: { height: 0, opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: {
      height: visible ? viewHeight : 0,
      opacity: visible ? 1 : 0,
      transform: `translate3d(${visible ? 0 : 20}px,0,0)`,
    },
  })

  const toggleVisible = () => {
    setVisible(!visible)
  }

  return (
    <StyledNavContainer>
      <StyledMobileAppBar data-testid="mobile-nav">
        <StyledNavTop>
          <StyledLogo
            to={homeSlug}
            contentType="ContentfulPage"
            style={{ marginTop: '7px' }}
          >
            <PHLogo width="116px" height="100%" id="qtph-globalmenu-phlogo" />
          </StyledLogo>
          <StyledNavToggleButton
            onClick={toggleVisible}
            aria-label="menu-button"
            role="menu-button"
          >
            <Icon glyph="menu" />
          </StyledNavToggleButton>
        </StyledNavTop>
        <StyledNavItems
          className="qtph-globalmenu-navigation"
          style={{
            opacity,
            height: visible && previous === visible ? 'auto' : height,
          }}
        >
          <a.div
            style={{ transform }}
            {...bind}
            children={<NavItemsMobile navItems={navItems} />}
          />
        </StyledNavItems>
      </StyledMobileAppBar>
    </StyledNavContainer>
  )
})

MobileAppBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  summary: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.object,
  ]),
  id: PropTypes.string,
  defaultOpen: PropTypes.bool,
  isList: PropTypes.bool,
}

export default MobileAppBar
