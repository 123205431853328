import React from 'react'
import PropTypes from 'prop-types'
import Stepper from '../Stepper'
import Controls from './components/Controls'
import Slide from './components/Slide'
import { StepperContainer, StyledCarousel, FullWidthWrapper } from './style'
import { HeroBannerWithOverlay } from '../Banner/components/Banner'
import {
  useCarousel,
  useMediaQuery,
  useLocale,
  useTranslatedTerms,
} from '@hooks'
import StyledInsightsSlide from './components/InsightsSlide'
import StyledTwoThirdsSlide from './components/TwoThirdsSlide'
import StyledFullWidthSlide from './components/FullWidthSlide'
import insightsBg from '@assets/images/insightsbg.jpg'
import defaultBg from '@assets/images/Homepage_Awards_Bg.webp'
import { sizes } from '@theme'
import { nodeLocaleToEnglishLanguage } from '../../../gatsby/createPagesUtils/nodeHelpers'

const defaultGatsbyBg = {
  image: {
    fluid: {
      src: defaultBg,
    },
  },
}
const defaultInsightsBg = {
  fluid: {
    src: insightsBg,
  },
}

const Carousel = ({
  autoPlay = false,
  controls = false,
  stepper = true,
  align = 'left',
  children,
  slidesPerView = 1,
  type,
  controlTop,
  stepperColor,
  layout,
  ...props
}) => {
  const sm = useMediaQuery(`(max-width: ${sizes.small.max})`)
  const numberOfSteps = sm
    ? children.length
    : layout === 'slider'
    ? children.length
    : Math.ceil(children.length / slidesPerView)
  const [index, setIndex, next, prev, transitions] = useCarousel(
    children.length,
    numberOfSteps,
    autoPlay
  )

  return (
    <StyledCarousel>
      {controls && (
        <Controls
          controlTop={controlTop}
          next={next}
          prev={prev}
          autoPlay={autoPlay}
        />
      )}
      {transitions.map(({ item, props, key }) => (
        <Slide key={key} style={props} type={type}>
          {children[item]}
        </Slide>
      ))}
      {stepper && (
        <StepperContainer align={align} {...props}>
          <Stepper
            activeStep={index + 1}
            numberOfSteps={numberOfSteps}
            circle
            color="white"
            setIndex={setIndex}
            stepperColor={stepperColor}
          />
        </StepperContainer>
      )}
    </StyledCarousel>
  )
}

const CarouselWithSlides = ({
  layout,
  slides,
  heroImage,
  mobileHeight,
  desktopHeight,
  ...rest
}) => {
  const { locale } = useLocale()
  const { getTranslatedTerm } = useTranslatedTerms()
  const display = slide =>
    !slide?.contentDestination?.[0]?.displayOnSites ||
    slide?.contentDestination?.[0]?.displayOnSites?.includes(
      nodeLocaleToEnglishLanguage[locale]
    )
  slides = slides?.filter(slide => display(slide))

  switch (layout) {
    case 'fullwidth':
      return (
        <FullWidthWrapper
          mobileHeight={mobileHeight}
          desktopHeight={desktopHeight}
        >
          {slides && slides.length > 0 && (
            <Carousel type="fullwidth" align="center" controls>
              {slides.map(slide => (
                <StyledFullWidthSlide
                  slide={slide}
                  key={slide.id}
                  tag="insight"
                  mobileHeight={mobileHeight}
                  desktopHeight={desktopHeight}
                  {...rest}
                />
              ))}
            </Carousel>
          )}
        </FullWidthWrapper>
      )
    case 'slider':
      return (
        <HeroBannerWithOverlay
          align="left"
          height="md"
          heroImage={defaultGatsbyBg.image}
          text={false}
          gradient={false}
          style={{ maxWidth: '2000px', margin: '0px' }}
        >
          {slides && slides.length > 0 && (
            <Carousel
              {...rest}
              type="slider"
              slidesPerView={2}
              align="center"
              layout={layout}
            >
              {slides.map((slide, i) => (
                <StyledTwoThirdsSlide
                  slides={slides}
                  slide={[slide, slides[i + 1], slides[i + 2]]}
                  key={slide.id}
                  tag="insight"
                />
              ))}
            </Carousel>
          )}
        </HeroBannerWithOverlay>
      )
    default:
      return (
        <HeroBannerWithOverlay
          align="left"
          height="lg"
          heroImage={defaultInsightsBg}
          gradient="purple"
          text={false}
        >
          {slides?.length && (
            <Carousel
              autoPlay
              stepper
              stepperPadding="0px 0px 60px"
              mobileStepperPadding="0px 0px 40px"
              type="default"
            >
              {slides?.map(slide => (
                <StyledInsightsSlide
                  slide={slide}
                  key={slide.id}
                  tag={getTranslatedTerm('Featured Insight')}
                  mobileSlidePadding="max-[450px]:py-[25px] max-[450px]:px-0"
                  slidePadding="py-[50px] px-0"
                />
              ))}
            </Carousel>
          )}
        </HeroBannerWithOverlay>
      )
  }
}
Carousel.propTypes = {
  autoPlay: PropTypes.bool,
  controls: PropTypes.bool,
  stepper: PropTypes.bool,
  align: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default CarouselWithSlides
export { Carousel }
export const InsightsSlide = ({ slide, ...rest }) => (
  <StyledInsightsSlide slide={slide} {...rest} />
)
export const TwoThirdsSlide = ({ slide, ...rest }) => (
  <StyledTwoThirdsSlide slide={slide} {...rest} />
)
export const FullWidthSlide = ({ slide, height, ...rest }) => (
  <StyledFullWidthSlide slide={slide} height={height} {...rest} />
)
