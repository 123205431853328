import React from 'react'
import { StyledFooter, FooterLinks, FooterRow } from '../style'
import FooterCol from './FooterCol'
import FooterSocial from './FooterSocial'
import Copyright from './Copyright'
import { Container } from '../../Globals'

const Footer = ({ items, copyRightData, displaySRA }) => {
  return (
    <StyledFooter>
      <FooterLinks>
        <Container className="qtph-footer">
          <FooterRow>
            <FooterCol items={items} />
            <FooterSocial displaySRA={displaySRA} />
          </FooterRow>
        </Container>
      </FooterLinks>
      <Copyright data={copyRightData} />
    </StyledFooter>
  )
}

export default Footer
