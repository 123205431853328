import Debug from '@components/Debug'
import React from 'react'
import EmbeddedEntrySubContainer from './EmbeddedEntrySubContainer'

const EmbeddedEntryCDAContainer = ({ node, references, ...rest }) => {
  const targetId = node?.data?.target?.sys?.id
  const linkType = node?.data?.target?.sys?.linkType
  const entry = references?.find(({ id }) => id === targetId)

  if (!references?.length) {
    return (
      <Debug
        label={`No references found in Embedded Entry CDA Container for targetId: ${targetId}`}
        jsonDump={{ node, entry }}
      />
    )
  }

  if (!entry) {
    return (
      <Debug
        label={`No entry found in Embedded Entry CDA Container for targetId: ${targetId}`}
        jsonDump={{ node, references }}
      />
    )
  }

  return <EmbeddedEntrySubContainer {...rest} {...entry} linkType={linkType} />
}

export default EmbeddedEntryCDAContainer
