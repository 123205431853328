import React from 'react'
import { ListH2, StyledSubtitle } from '../style'
import List from '@components/List'
import { A } from '@components/Button/index'
import { nodeLocaleToEnglishLanguage } from '@utils'
import { DotListTextContainer } from '@components/List/style'
import { useLocale } from '@hooks'

export const StyledList = ({ listItems, listAppearance, linkId }) => {
  const { locale } = useLocale()

  const display = linksTo => {
    const { displayOnSites } = linksTo
    return (
      !displayOnSites ||
      displayOnSites?.includes(nodeLocaleToEnglishLanguage[locale])
    )
  }

  if (listAppearance === 'Bordered List') {
    return (
      <List timeline width="100%">
        {listItems?.map(({ title, subtitle, linksTo }, i) => (
          <div key={`list-item-${i + 1}`}>
            {linksTo && display(linksTo[0]) ? (
              <DotListTextContainer>
                <A
                  to={linksTo?.[0]?.slug}
                  contentType={linksTo?.[0]?.__typename}
                  category={linksTo?.[0]?.category?.slug}
                >
                  <ListH2
                    textCase="default"
                    color="#5A3462"
                    fontSize="18px"
                    margin="0px"
                    noDivider
                    underline
                    padding="0px"
                    letterSpacing="normal"
                    style={{ textAlign: 'left' }}
                  >
                    {title?.listText || title}
                  </ListH2>
                </A>
                {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
              </DotListTextContainer>
            ) : (
              <DotListTextContainer>
                <ListH2
                  textCase="default"
                  color="#5A3462"
                  fontSize="18px"
                  margin="0px"
                  noDivider
                  underline
                  padding="0px"
                  letterSpacing="normal"
                  style={{ pointerEvents: 'none' }}
                >
                  {title?.listText || title}
                </ListH2>
                {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
              </DotListTextContainer>
            )}
          </div>
        ))}
      </List>
    )
  }

  return null
}
